.navbar-color {
  background-color: #10490e; /* A nice navy blue color */
  border-bottom: 2px solid #002752; /* Adds a subtle border at the bottom */
}

.logo-text-color {
  color: #ffffff; /* White color for the logo text */
  font-weight: bold;
}

.navbar-toggler {
  border-color: #ffffff; /* White color for the toggle button */
}

.navbar-toggler-icon {
  /* background-color: #ffffff; White color for the toggle icon */
}

.navbar-collapse {
  justify-content: flex-end; /* Aligns the navbar items to the right */
}

.navbar-nav .nav-link {
  color: #ffffff !important; /* White color for the links */
  margin: 0.25rem 0; /* Small margin for better spacing */
}

.navbar-nav .nav-link:hover {
  color: #d4d4d4 !important; /* Lighter shade on hover */
}

.navbar-nav .btn {
  margin-bottom: 0.25rem; /* Small bottom margin between buttons */
  font-size: 0.875rem; /* Slightly smaller font for better fitting */
}

@media (max-width: 768px) {
  .navbar-nav .btn {
    width: 100%; /* Full-width buttons on smaller screens */
    text-align: left; /* Align text to the left for readability */
    margin: 0.125rem 0; /* Adjusted spacing between buttons */
  }
}

.navbar-brand {
  margin-right: 1rem; /* Adds space between the logo and the first button */
}

.navbar-color {
  background-color: #10490e; 
  border-bottom: 2px solid #002752;
  z-index: 1030; /* Ensures navbar is on top of other elements */
}

body {
  padding-top: 70px; /* Adjust padding if navbar height is around 70px */
}


