/* General styling for the sidebar container */
.sidebar-frame {
  width: 25%;
  height: 100%;
  background-color: #f8f9fa;
  padding: 10px;
}

/* Update sidebar width for mobile view */
@media (max-width: 390px) {
  .sidebar-frame {
    width: 75%; /* Adjust width for mobile screens */
    max-width: 75%; /* Optionally limit maximum width */
  }
}

/* Styling for the links inside the sidebar */
.text-styl {
  display: block;
  padding: 10px;
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
}

/* Change link color on hover */
.text-styl:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Specific styles for each column (add your own customization as needed) */
.side-bar-col5 {
  background-color: #e9ecef;
  margin-bottom: 10px;
  border-radius: 4px;
}

.side-bar-col4 {
  background-color: #dee2e6;
  margin-bottom: 10px;
  border-radius: 4px;
}

.side-bar-col3 {
  background-color: #ced4da;
  margin-bottom: 10px;
  border-radius: 4px;
}

.side-bar-col2 {
  background-color: #adb5bd;
  margin-bottom: 10px;
  border-radius: 4px;
}

.side-bar-col1 {
  background-color: #6c757d;
  margin-bottom: 10px;
  border-radius: 4px;
}

/* Optional: Add some padding and styling to the Offcanvas body */
.offcanvas-body {
  padding: 20px;
}
.offCanvasArea{
  min-width: 15%;
  max-width: 20%;
  margin: 0 auto;
  text-size-adjust: 10%;
}

.quick-access-button {
  display: flex;
  position: fixed;
  bottom: 75px;
  left: -130px;
  z-index: 1050; /* Ensure it appears above other content */
  width: 160px; /* Small icon size */
  height: 60px;
  border-radius: 5px; /* Circular button */
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, left 0.3s ease-in-out, bottom 0.3s ease-in-out;  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1; /* Background color of the button */
}

.quick-access-button:hover {
  width: 160px; /* Expand width on hover */
  height: 60px; /* Keep height the same */
  left: 20px; /* Stay in the same position from the left */
}

.quick-access-button span {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.quick-access-button:hover span {
  opacity: 1; /* Show text when hovered */
}

