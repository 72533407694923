.card-text {
  font-size: 1.2vw; /* Responsive font size based on view width */
}

@media (max-width: 1200px) {
  .card-text {
    font-size: 1.5vw;
  }
}

@media (max-width: 992px) {
  .card-text {
    font-size: 2vw;
  }
}

@media (max-width: 768px) {
  .card-text {
    font-size: 3vw;
  }
}

@media (max-width: 576px) {
  .image-container .card-text {
    font-size: 2vw;
  }
  .image-container .card-title {
    font-size: 4vw;
  }
}
