.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(88, 39, 39);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.no-gutters {
  margin-right: 0;
  margin-left: 0;

}

.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.main-frame {
  background-color: #578167;
  justify-content: left;
  min-height: 70vh;
  font-size: calc(10px + 2vmin);
  color: rgb(220, 235, 87);
  text-decoration: none;
  margin-left:0.75rem;
  margin-top: 10px;
}

.mainPageCarousels-style {
  background-color: #8db0b4a2;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(88, 39, 39);
 
}

.frame-size{
  height: 100px;
  width: 100px;
  text-size-adjust: 12px;
}

.frame-text-size{
  font-size: 12px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.navbar-color{
  background-color: #294237;
  color: #fff;
}

.login-container-frame{
  margin-top: 3rem;
  margin-left: 25%;
  width: 35%; /* Set the width */
  height: 50%; /* Set the height */
}


@media (min-width: 300px) {
  .login-container-frame{
    margin-top: 3rem;
    margin-left: 10%;
    width: 75%; /* Set the width */
    height: 50%; /* Set the height */
  }
}
@media (min-width: 400px) {
  .login-container-frame{
    margin-top: 3rem;
    margin-left: 10%;
    width: 75%; /* Set the width */
    height: 50%; /* Set the height */
  }
}
@media (min-width: 700px) {
  .login-container-frame{
    margin-top: 3rem;
    margin-left: 25%;
    width: 35%; /* Set the width */
    height: 50%; /* Set the height */
  }
}

.error-msg{
  color: red;
}
.custom-indent-space{
  margin: 1%;
}
.indent-position{
  margin-top: 1%;
  margin-left: 1%;
}
.backButton-indent{
  margin-top: 1%;
  margin-left: 2%;
}
.card {
  transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition */
}

.card.selected {
  opacity: 0.25; /* Make the card semi-transparent */
}

.card:not(.selected) {
  opacity: 1; /* Make the card fully opaque when not selected */
}
.empty-navbar {
  position: fixed; /* Ensures the navbar stays fixed at the top */
  top: 0; /* Aligns the navbar at the top of the viewport */
  width: 100%; /* Spans the full width of the screen */
  z-index: 1000; /* Ensures the navbar appears above other content */
  /* Add other styling properties as needed */
}

.divFlexSpan {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Align figures horizontally */
}

.figure-wrapper {
  margin: 10px; /* Adjust spacing between figures */
}


.ordersTemp-itemAlign {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ordersTemp-itemAlign .quantity-buttons {
  display: flex;
  align-items: center;
}

/* IndentPage_Styles.css */

/* Container to center content horizontally and align to the top */
.indent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Aligns items to the top */
  padding-top: 20px; /* Optional: Adds space from the top */
}

/* Style for buttons inside the container */
.indent-button {
  margin: 10px;
}
.auto-layout-table {
  table-layout: auto;  /* Step 1: Set table-layout to auto */
  width: 75%;
}
.footer-layout{
  height: 10vh;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: top;
 
}

.image-fixed-size {
  max-width: 100%; /* Ensures image doesn't overflow its container */
  height: auto;    /* Maintains aspect ratio */

  /* For small screens */
  width: 100px;
  height: 100px;

  /* For medium screens */
  @media (min-width: 768px) {
    width: 200px;
    height: 200px;
  }

  /* For large screens */
  @media (min-width: 1024px) {
    width: 300px;
    height: 300px;
  }
}




.fixed-button-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.fixed-button-left {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-margin {
  margin: 2px;
}


