.offers-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.offers-title{
    margin: 1rem;
    font-size: 24px;
    font-weight: bold;
}