.main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    align-content: center;
}
.welcome-container{
    margin: 5rem;
    padding: 5rem;
    border: 2px solid lightgray;
    border-radius: 10px;
    font-weight: bold;
    font-size: 32px;
}